import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import firebase from "../../lib/firebase";
import CompanyForm from "../../components/forms/company-form";

const CompanyPage = () => {
  const { id } = useParams();

  const [company, setCompany] = useState({ loading: true });
  const [error, setError] = useState();

  function saveCompany(attrs) {
    const db = firebase.firestore();

    const docRef = db.collection("companies").doc(id);
    docRef.update(attrs).then(() => setCompany({ ...company, ...attrs }));
  }

  useEffect(() => {
    setCompany({ loading: true });

    const db = firebase.firestore();

    const docRef = db.collection("companies").doc(id);

    docRef
      .get()
      .then(doc => setCompany({ ...doc.data(), uid: doc.id, loading: false }))
      .catch(e => setError(e.message));
  }, [id]);

  if (error) {
    return error;
  }

  if (company.loading) {
    return "Loading...";
  }

  return (
    <div className="container mx-auto">
      <div className="company">
        <h1 className="text-4xl py-4">{company.name}</h1>

        <Link to={`/companies/${company.uid}/explore`} className="block text-center">
          <button type="button" className="btn btn-blue">
            Explore
          </button>
        </Link>

        <CompanyForm company={company} setCompany={setCompany} saveCompany={saveCompany} />
      </div>
    </div>
  );
};

export default CompanyPage;
