import React, { useEffect, useState } from "react";
import firebase from "../../lib/firebase";
import { Link } from "react-router-dom";

const CompaniesListPage = () => {
  const [companies, setCompanies] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const db = firebase.firestore();

    db.collection("companies")
      .get()
      .then(result => setCompanies(result.docs))
      .catch(e => setError(e.message));
  }, []);

  if (error) {
    if (error === "Missing or insufficient permissions.") {
      return (
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSfYAvk7vqXYiG5_75PFCrO_aSOP1Q_BSeFPF5kNduvHdPRJBA/viewform?embedded=true"
          width="100%"
          height="100%"
          frameBorder="0"
          marginWidth="0"
          marginHeight="0"
        >
          Loading...
        </iframe>
      );
    }

    return error;
  }

  return (
    <>
      <div className="container mx-auto py-4">
        <h1 className="text-4xl">Companies</h1>
        <div className="companies py-4">
          {companies
            ? companies.map(company => (
                <div key={company.id} className="company">
                  <Link className="underline" to={`/companies/${company.id}`}>
                    {company.get("name")}
                  </Link>
                </div>
              ))
            : "Loading..."}
        </div>

        <Link className="btn btn-blue" to="/companies/new">
          New Company
        </Link>
      </div>
    </>
  );
};

export default CompaniesListPage;
