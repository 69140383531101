import Template from "./template.js";
import { timeout } from "use-task";

export default function* generate(config, setTryCount, setResults) {
  const { layers, variables, constraints, maxRuns, maxVariations } = config;

  // Debounce
  yield timeout(50);

  const template = new Template(layers, variables, constraints);

  let outputs = [];
  let tryCount = 0;
  for (let i = 0; i < maxRuns && outputs.length < maxVariations; i++) {
    tryCount = i + 1;
    const output = yield template.generateOutput();

    // No point in needlessly updating the UI too much
    if (i % 50 === 0) {
      setTryCount(tryCount);

      // Let it breathe a little, otherwise we lock the runloop
      yield timeout(0);
    }

    if (!(output.errors && output.errors.length)) {
      output.key = `variation-${Math.random()}`;
      outputs.push(output);
      setResults([...outputs]);

      setTryCount(tryCount);
    }
  }

  setTryCount(tryCount);
}
