const configs = {
  production: {
    apiKey: "AIzaSyDQlCgEcob_kqaGxQkLB-voXb6ZyadaeMA",
    authDomain: "movableink-warhol.firebaseapp.com",
    databaseURL: "https://movableink-warhol.firebaseio.com",
    projectId: "movableink-warhol",
    storageBucket: "movableink-warhol.appspot.com",
    messagingSenderId: "1070386638603",
    appId: "1:1070386638603:web:e7ab65cbfc71c2ddf1bc0e",
  },
  development: {
    apiKey: "AIzaSyA3nCIq4xPyYVJ9oSFOs6l4q8cfxtPIREA",
    authDomain: "movableink-warhol-development.firebaseapp.com",
    databaseURL: "https://movableink-warhol-development.firebaseio.com",
    projectId: "movableink-warhol-development",
    storageBucket: "movableink-warhol-development.appspot.com",
    messagingSenderId: "431314540410",
    appId: "1:431314540410:web:93d7639f3233cb7abee8c3",
  },
};

const env = process.env.NODE_ENV || "development";
const config = configs[env];

if (!config) {
  throw new Error(
    `Unknown environment: ${env}; valid values are: ${Object.keys(configs).join(",")}`
  );
}

module.exports = config;
