import firebase from "firebase/app";
import config from "../firebase.config";

import "firebase/auth";
import "firebase/firestore";

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export default firebase;
