import React, { useState } from "react";
import PropTypes from "prop-types";

import App from "../app";
import DebugInfo from "./debug-info";

const ResultSingle = ({ result, config }) => {
  const [expanded, setExpanded] = useState();

  if (!result) {
    return <></>;
  }

  const { layers } = result;
  const { dimensions } = config.template;

  return (
    <div className="flex justify-center flex-col h-screen">
      <div className="flex justify-center">
        <App tags={layers} options={dimensions} />
        {expanded || (
          <button
            type="button"
            className="inline-block px-4 pb-4 self-start"
            onClick={() => setExpanded(true)}
          >
            INFO
          </button>
        )}
        {expanded && (
          <div className="bg-white text-black">
            <DebugInfo result={result} dimensions={dimensions} />
          </div>
        )}
      </div>
    </div>
  );
};

ResultSingle.propTypes = {
  result: PropTypes.shape({
    layers: PropTypes.array.isRequired,
    debugInfo: PropTypes.object.isRequired,
  }),
  config: PropTypes.shape({
    template: PropTypes.shape({
      dimensions: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default ResultSingle;
