import React, { memo } from "react";
import PropTypes from "prop-types";
import ConstraintSetting from "./constraint-setting";

const ConstraintsForm = ({ config, setConfig }) => {
  const { constraints } = config;

  function updateConstraintToggle(constraint, event) {
    constraint.enabled = event.target.checked;
    setConfig({ ...config, constraints: [...constraints] });
  }

  function updateConstraintSetting(constraint, setting) {
    Object.assign(
      constraint.settings.find(s => s.name === setting.name),
      setting
    );
    setConfig({ ...config, constraints: [...constraints] });
  }

  return (
    <div className="constraints-form p-4">
      {constraints.map(constraint => (
        <div key={constraint.name} className="constraint pt-3 pl-1">
          <div className="flex">
            <input
              className="mt-2 mr-2"
              checked={constraint.enabled}
              type="checkbox"
              onChange={e => updateConstraintToggle(constraint, e)}
            />
            <div className="flex-1">{constraint.name}</div>
          </div>
          {constraint.settings.map(setting => (
            <ConstraintSetting
              key={setting.name}
              setting={setting}
              update={s => updateConstraintSetting(constraint, s)}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

ConstraintsForm.propTypes = {
  config: PropTypes.object.isRequired,
  setConfig: PropTypes.func.isRequired,
};

export default memo(ConstraintsForm);
