function range(min, max, step = 1) {
  return Array(Math.floor((max - min) / step) + 1)
    .fill(min)
    .map((x, i) => x + i * step);
}

export default class Variable {
  constructor(config, layers) {
    this.config = config;

    if (config.select) {
      if (!Array.isArray(config.select)) {
        config.select = [config.select];
      }

      config.select.forEach(select => {
        select.layerIndex = layers.findIndex(l => {
          return Object.entries(select.layer).every(([key, value]) => l[key] === value);
        });
      });

      if (!config.select.some(s => s.layerIndex >= 0)) {
        console.error(`Did not match any layers: ${JSON.stringify(config.select)}`);
      }
    }
  }

  replace(layers) {
    let value = this.getValue();

    if (this.config.enabled) {
      for (let select of this.config.select) {
        const { path, layerIndex } = select;

        const layer = layers[layerIndex];
        if (layer) {
          const parts = path.split(".");
          const key = parts.pop();
          const parent = parts.reduce((o, i) => o[i], layer);

          parent[key] = value;
        }
      }
    }

    return value;
  }

  get name() {
    return this.config.name;
  }

  getValue() {
    const { type } = this.config;

    if (type === "enum") {
      const { values } = this.config;
      return values[Math.floor(Math.random() * values.length)];
    } else if (type === "range") {
      const { min, max, step } = this.config;
      const values = range(min, max, step);
      return values[Math.floor(Math.random() * values.length)];
    } else {
      return null;
    }
  }
}
