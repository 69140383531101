import React, { useState } from "react";
import logo from "../images/logo.svg";

import { useAuth } from "../context/auth";

const User = () => {
  const [isOpen, setIsOpen] = useState();
  const { user, logout } = useAuth();

  return (
    <div className="user h-5">
      <div
        className="cursor-pointer bg-gray-900 my-2 p-3 rounded"
        onClick={() => setIsOpen(!isOpen)}
      >
        {user.email} &nbsp;
        <img alt="User avatar" className="inline-block h-6" src={user.photoURL} />
      </div>

      <div
        className={`dropdown -mt-2 py-2 w-auto bg-white rounded relative rounded-t-none border border-1 ${
          isOpen ? "block" : "hidden"
        }`}
      >
        <div
          className="logout block px-4 py-2 text-gray-800 hover:bg-gray-500 hover:text-white"
          onClick={logout}
        >
          Log out
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  const { isLoggedIn } = useAuth();

  return (
    <div className="w-full block bg-black text-white px-4 flex">
      <div>
        <img alt="Warhol logo" src={logo} className="h-5 mx-2 my-6" />
      </div>
      <div className="flex-1"></div>
      {isLoggedIn && <User />}
    </div>
  );
};

export default Header;
