import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Helmet from "react-helmet";
import firebase from "../lib/firebase";

import Explore from "../components/explore";

const ExplorePage = () => {
  const { id } = useParams();

  const [templates, setTemplates] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    (async () => {
      try {
        const db = firebase.firestore();

        const result = await db.collection("templates").get();

        const templates = result.docs.map(doc => {
          const template = doc.data();
          const layers = JSON.parse(template.layers);
          const variables = JSON.parse(template.variables);
          return { ...template, layers, variables };
        });

        setTemplates(templates);
      } catch (e) {
        setError(e.message);
      }
    })();
  }, [id]);

  if (error) {
    return error;
  }

  if (!templates) {
    return "Loading...";
  }

  return (
    <>
      <Helmet>
        <link type="text/css" rel="stylesheet" href="/fonts.css" />
      </Helmet>

      <Explore templates={templates} />
    </>
  );
};

export default ExplorePage;
