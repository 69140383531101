import React, { useState } from "react";
import PropTypes from "prop-types";
import { SketchPicker } from "react-color";
import "./color-picker.css";

const ColorPicker = ({ color, setColor, className }) => {
  const [visible, setVisible] = useState(false);

  const handleClick = () => setVisible(!visible);
  const handleClose = () => setVisible(false);

  const handleChange = color => {
    if (color.rgb.a < 1) {
      const { r, g, b, a } = color.rgb;
      setColor(`rgba(${r},${g},${b},${a})`);
    } else {
      setColor(color.hex);
    }
  };

  return (
    <div className={`color-picker ${className}`}>
      <div className="swatch" onClick={handleClick}>
        <div className="color" style={{ background: color }} />
      </div>

      {visible ? (
        <div className="popover">
          <div className="cover" onClick={handleClose} />
          <SketchPicker color={color} onChangeComplete={handleChange} />
        </div>
      ) : null}
    </div>
  );
};

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  setColor: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default ColorPicker;
