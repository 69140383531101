import React, { useState, useRef, memo } from "react";
import PropTypes from "prop-types";
import "./loader.css";

const Loader = ({ config, results, setConfig, setResults }) => {
  const [expanded, setExpanded] = useState(false);
  const [changed, setChanged] = useState(false);

  let serialized;
  if (expanded) {
    serialized = JSON.stringify({ config, results }, null, 2);
  }

  const textarea = useRef();

  function save() {
    const { value } = textarea.current;
    try {
      const { config, results } = JSON.parse(value);
      setConfig(config);
      setResults(results);
      setChanged(false);
      setExpanded(false);
    } catch (e) {
      alert(`Failed to parse input: ${e.message}`);
    }
  }

  function copy() {
    textarea.current.select();
    document.execCommand("copy");
  }

  function reset() {
    serialized = serialized + "";
    setChanged(false);
    setExpanded(false);
  }

  let buttons;

  if (expanded && changed) {
    buttons = [
      <button key="close" type="button" className="btn action-button" onClick={() => reset()}>
        Close
      </button>,
      <button key="save" type="button" className="btn action-button" onClick={() => save()}>
        Save
      </button>,
    ];
  } else if (expanded) {
    buttons = [
      <button key="close" type="button" className="btn action-button" onClick={() => reset()}>
        Close
      </button>,
      <button key="save" type="button" className="btn action-button" onClick={() => copy()}>
        Copy
      </button>,
    ];
  } else {
    buttons = (
      <button type="button" className="btn action-button" onClick={() => setExpanded(!expanded)}>
        Import / Export
      </button>
    );
  }

  return (
    <div className="import-export">
      {expanded && (
        <div>
          <p className="p-4 pb-2 pt-2">
            Copy this textarea to save the state; paste into here to load a saved state.
          </p>
          <div className="flex">
            <textarea
              defaultValue={serialized}
              ref={textarea}
              className="form-textarea text-black flex-1 m-4 h-64"
              onChange={() => setChanged(true)}
            ></textarea>
          </div>
        </div>
      )}
      <div className="actions flex">{buttons}</div>
    </div>
  );
};

Loader.propTypes = {
  config: PropTypes.object.isRequired,
  results: PropTypes.array.isRequired,
  setConfig: PropTypes.func.isRequired,
  setResults: PropTypes.func.isRequired,
};

export default memo(Loader);
