import React from "react";
import PropTypes from "prop-types";

const VariableRange = ({ setVariable, variable }) => {
  return (
    <div className="flex flex-row">
      <div className="col flex-1 mr-2">
        <label>
          Minimum
          <input
            type="number"
            className="form-input mt-1 block w-auto"
            value={variable.min}
            onChange={e => setVariable(variable.key, { min: parseInt(e.target.value) })}
          />
        </label>
      </div>

      <div className="col flex-1 mr-2">
        <label>
          Step
          <input
            type="number"
            className="form-input mt-1 block w-auto"
            value={variable.step}
            onChange={e => setVariable(variable.key, { step: parseInt(e.target.value) })}
          />
        </label>
      </div>

      <div className="col flex-1 mr-2">
        <label>
          Maximum
          <input
            type="number"
            className="form-input mt-1 block w-auto"
            value={variable.max}
            onChange={e => setVariable(variable.key, { max: parseInt(e.target.value) })}
          />
        </label>
      </div>
    </div>
  );
};

VariableRange.propTypes = {
  setVariable: PropTypes.func.isRequired,
  variable: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired,
  }),
};

export default VariableRange;
