import React, { useState } from "react";
import PropTypes from "prop-types";

import VariableText from "./text";
import VariableColor from "./color";
import VariableImage from "./image";
import VariableRange from "./range";

import VariableSettings from "./settings";
import { IoMdSettings } from "react-icons/io";

const VariableControl = ({ variable, config, setConfig, editable }) => {
  let input;
  const { variables } = config;

  const isEditable = editable !== false;

  function updateValue(key, position, value) {
    variables.find(v => v.key === key).values[position] = value;
    setConfig({ ...config, variables: [...variables] });
  }

  function addValue(key, defaultValue = "") {
    variables.find(v => v.key === key).values.push(defaultValue);
    setConfig({ ...config, variables: [...variables] });
  }

  function removeValue(key, position) {
    variables.find(v => v.key === key).values.splice(position, 1);
    setConfig({ ...config, variables: [...variables] });
  }

  function setVariable(key, newVariable) {
    const oldVariable = variables.find(v => v.key === key);
    Object.assign(oldVariable, newVariable);
    setConfig({ ...config, variables: [...variables] });
  }

  function deleteVariable(key) {
    setConfig({ ...config, variables: variables.filter(v => v.key !== key) });
  }

  const [settingsVisible, setSettingsVisible] = useState();

  if (variable.type === "enum") {
    if (variable.format === "text") {
      input = (
        <VariableText
          addValue={addValue}
          updateValue={updateValue}
          removeValue={removeValue}
          variable={variable}
        />
      );
    } else if (variable.format === "number") {
      input = (
        <VariableText
          addValue={addValue}
          updateValue={updateValue}
          removeValue={removeValue}
          type="number"
          variable={variable}
        />
      );
    } else if (variable.format === "color") {
      input = (
        <VariableColor
          addValue={addValue}
          updateValue={updateValue}
          removeValue={removeValue}
          variable={variable}
        />
      );
    } else if (variable.format === "image") {
      input = (
        <VariableImage
          addValue={addValue}
          updateValue={updateValue}
          removeValue={removeValue}
          variable={variable}
        />
      );
    } else {
      input = <div>Variables of format {variable.format} are unsupported.</div>;
    }
  } else if (variable.type === "range") {
    input = <VariableRange setVariable={setVariable} variable={variable} />;
  } else {
    input = <div>Variables of type {variable.type} are unsupported.</div>;
  }

  return (
    <div key={variable.key} className="block p-4 border-b border-gray-600">
      <div className="flex">
        <div className="block font-mono tracking-wide text-gray-700 mb-2 text-sm font-bold flex-1">
          {variable.name}
        </div>

        {isEditable && (
          <>
            <button
              type="button"
              className={
                settingsVisible ? "variable-settings variable-settings-active" : "variable-settings"
              }
            >
              <IoMdSettings
                title="Variable Settings"
                onClick={() => setSettingsVisible(!settingsVisible)}
              />
            </button>
            <input
              type="checkbox"
              style={{ marginTop: "3px" }}
              checked={variable.enabled}
              onChange={() => setVariable(variable.key, { enabled: !variable.enabled })}
            />
          </>
        )}
      </div>

      {settingsVisible && (
        <VariableSettings
          variable={variable}
          setVariable={setVariable}
          deleteVariable={() => deleteVariable(variable.key)}
        />
      )}

      {variable.enabled && input}
    </div>
  );
};

VariableControl.propTypes = {
  variable: PropTypes.shape({
    type: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }),
  config: PropTypes.shape({
    variables: PropTypes.array.isRequired,
  }),
  setConfig: PropTypes.func.isRequired,
  editable: PropTypes.bool,
};

export default VariableControl;
