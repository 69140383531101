import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../../lib/firebase";

const NewCompanyPage = () => {
  const [company, setCompany] = useState({ name: "" });
  const history = useHistory();

  function handleChange(e) {
    setCompany({ name: e.target.value });
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!company.name || !company.name.length) {
      alert("Company must have a name");
    }

    const db = firebase.firestore();

    db.collection("companies")
      .add(company)
      .then(docRef => history.push(`/companies/${docRef.id}`));
  }

  return (
    <div className="container mx-auto py-4">
      <form onSubmit={handleSubmit} className="company">
        <h1 className="text-4xl">New Company</h1>

        <label>
          <div>Name:</div>
          <input
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3"
            value={company.name}
            onChange={handleChange}
          />
        </label>

        <button className="btn btn-blue my-4" onClick={handleSubmit}>
          Create
        </button>
      </form>
    </div>
  );
};

export default NewCompanyPage;
