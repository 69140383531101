import React from "react";
import { Switch, Route } from "react-router-dom";

import CompaniesListPage from "./list";
import CompanyPage from "./company";
import NewPage from "./new";

import ExplorePage from "../explore";

const CompaniesPage = () => {
  return (
    <Switch>
      <Route path="/companies/new" component={NewPage} />
      <Route path="/companies/:id/explore" component={ExplorePage} />
      <Route path="/companies/:id" component={CompanyPage} />
      <Route exact path="/" component={CompaniesListPage} />
    </Switch>
  );
};

export default CompaniesPage;
