import React from "react";
import PropTypes from "prop-types";

const ProgressBar = ({ value, total }) => {
  const percent = Math.round((value / total) * 100) + "%";
  const style = { width: percent };

  return (
    <div className="shadow w-full bg-gray-700">
      <div className="bg-gray-300 text-xs text-center text-gray-900 font-semibold" style={style}>
        &nbsp;{percent}&nbsp;
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default ProgressBar;
