import React, { memo } from "react";
import PropTypes from "prop-types";
import { PopupboxManager } from "react-popupbox";

import "./frame.css";
import App from "../app";

import DebugInfo from "./debug-info";

const ResultFrame = ({ result, dimensions }) => {
  const { layers } = result;
  function openPopupBox() {
    const popupStyle = { maxWidth: "80vw", maxHeight: "80vw" };
    const iframeStyle = {
      width: `${dimensions.width}px`,
      height: `${dimensions.height}px`,
    };

    const content = (
      <div className="flex text-black" style={popupStyle}>
        <div className="result-frame full m-4" style={iframeStyle}>
          <App tags={layers} options={dimensions} />
        </div>
        <DebugInfo result={result} dimensions={dimensions} />
      </div>
    );

    PopupboxManager.open({ content });
  }

  const wrapperStyle = {
    width: `${dimensions.width / 2}px`,
    height: `${dimensions.height / 2}px`,
  };

  return (
    <div style={wrapperStyle} className="result-frame mini" onClick={() => openPopupBox()}>
      <App tags={layers} options={dimensions} />
    </div>
  );
};

ResultFrame.propTypes = {
  result: PropTypes.shape({
    layers: PropTypes.array.isRequired,
    debugInfo: PropTypes.object.isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
};

export default memo(ResultFrame);
