import React, { useState } from "react";
import ColorPicker from "./util/color-picker";
import PropTypes from "prop-types";
import { IoMdClose } from "react-icons/io";

const VariableColor = ({ variable, addValue, removeValue, updateValue }) => {
  let fields;

  const [expanded, setExpanded] = useState(false);

  const lotsOfColors = variable.values.length > 10;

  if (lotsOfColors && !expanded) {
    fields = variable.values.map((value, position) => (
      <ColorPicker
        className="inline"
        key={["small", variable.key, position].join(".")}
        color={value}
        setColor={color => updateValue(variable.key, position, color)}
      />
    ));
  } else {
    fields = variable.values.map((value, position) => (
      <div className="row flex" key={[variable.key, position].join(".")}>
        <ColorPicker color={value} setColor={color => updateValue(variable.key, position, color)} />
        <input
          type="text"
          className="form-input mt-1 block flex-1"
          value={value}
          onChange={e => updateValue(variable.key, position, e.target.value)}
        />
        <button
          type="button"
          className="flex-none text-2xl p-1"
          onClick={() => removeValue(variable.key, position)}
        >
          <IoMdClose />
        </button>
      </div>
    ));
  }
  return (
    <div>
      {fields}
      <div className="actions flex">
        <button
          type="button"
          className="btn text-xs border-white self-start"
          onClick={() => addValue(variable.key, "#000000")}
        >
          + Add
        </button>
        {lotsOfColors && (
          <button
            type="button"
            className="btn text-xs border-white text-right flex-1"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "Collapse" : "Expand"}
          </button>
        )}
      </div>
    </div>
  );
};

VariableColor.propTypes = {
  variable: PropTypes.shape({
    values: PropTypes.array.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  addValue: PropTypes.func.isRequired,
  removeValue: PropTypes.func.isRequired,
  updateValue: PropTypes.func.isRequired,
};

export default VariableColor;
