import React from "react";
import PropTypes from "prop-types";

const ConstraintSetting = ({ setting, update }) => {
  let input;

  function onChange(value) {
    setting.value = value;
    update({ ...setting });
  }

  if (setting.format === "text") {
    input = (
      <input
        type="text"
        className="form-input"
        value={setting.value}
        onChange={e => onChange(e.target.value)}
      />
    );
  } else if (setting.format === "number") {
    if (setting.min && setting.max) {
      input = (
        <div className="flex">
          <input
            type="range"
            min={setting.min}
            max={setting.max}
            step={setting.step}
            className="flex-1"
            value={setting.value}
            onChange={e => onChange(parseFloat(e.target.value))}
          />
          <input
            type="number"
            className="form-input w-16 ml-4 flex-0"
            style={{ width: "80px" }}
            value={setting.value}
            onChange={e => onChange(parseFloat(e.target.value))}
          />
        </div>
      );
    } else {
      input = (
        <input
          type="number"
          value={setting.value}
          onChange={e => onChange(parseFloat(e.target.value))}
        />
      );
    }
  } else {
    throw new Error(`No format: ${setting.format}`);
  }

  return (
    <>
      <label className="block font-mono tracking-wide my-2 text-sm font-bold">{setting.name}</label>
      {input}
    </>
  );
};

ConstraintSetting.propTypes = {
  update: PropTypes.func.isRequired,
  setting: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    format: PropTypes.string.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
  }).isRequired,
};

export default ConstraintSetting;
