import React from "react";
import { Switch, Route } from "react-router-dom";

import CompaniesPage from "./pages/companies/index";
import NotFoundPage from "./pages/404";

import Header from "./components/header";

const App = () => (
  <div className="flex flex-col h-screen">
    <Header />
    <Switch>
      <Route path="/" component={CompaniesPage} />
      <Route component={NotFoundPage} />
    </Switch>
  </div>
);

export default App;
