import React from "react";
import PropTypes from "prop-types";

import ResultSet from "./results/set";
import ResultSingle from "./results/single";

const Results = ({ results, config, tryCount }) => {
  if (config.auto) {
    return <ResultSingle result={results[0]} config={config} />;
  } else {
    return <ResultSet results={results} config={config} tryCount={tryCount} />;
  }
};

Results.propTypes = {
  results: PropTypes.array.isRequired,
  config: PropTypes.shape({
    auto: PropTypes.bool.isRequired,
  }).isRequired,
  tryCount: PropTypes.number.isRequired,
};

export default Results;
