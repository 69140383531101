import React from "react";
import PropTypes from "prop-types";
import DropboxChooser from "react-dropbox-chooser";
import thumbnail from "../../../lib/thumbnail";
import { IoMdClose } from "react-icons/io";

const DROPBOX_APP_KEY = "5h00nw27fxb5530";

const THUMB_MAX_WIDTH = 340;
const THUMB_MAX_HEIGHT = 400;

function makeThumbnail(url) {
  return thumbnail(url, {
    width: THUMB_MAX_WIDTH,
    height: THUMB_MAX_HEIGHT,
    operation: "resize",
    fit: "inside",
  });
}

const VariableImage = ({ variable, addValue, removeValue }) => {
  function addFromDropbox(files) {
    for (let file of files) {
      addValue(variable.key, file.link);
    }
  }

  function clearAll() {
    const count = variable.values.length;
    for (let i = 0; i < count; i++) {
      removeValue(variable.key, 0);
    }
  }

  const fields = variable.values.map((value, position) => (
    <div className="row flex" key={[variable.key, position].join(".")}>
      <div className="thumbnail flex-1 text-center m-1">
        <img
          alt="creative thumbnail"
          className="inline-block p-1 shadow-lg bg-white rounded-sm"
          src={makeThumbnail(value)}
          style={{
            maxWidth: `${THUMB_MAX_WIDTH}px`,
            maxHeight: `${THUMB_MAX_HEIGHT}px`,
            height: "auto",
          }}
        />
      </div>
      <button
        type="button"
        className="flex-none text-2xl p-1"
        onClick={() => removeValue(variable.key, position)}
      >
        <IoMdClose />
      </button>
    </div>
  ));

  return (
    <div>
      <div className="text-right">
        <button type="button" className="btn text-xs display-inline" onClick={clearAll}>
          Clear all
        </button>
      </div>
      {fields}
      <DropboxChooser
        appKey={DROPBOX_APP_KEY}
        success={addFromDropbox}
        linkType="direct"
        multiselect={true}
      >
        <div className="btn text-xs cursor-pointer">+ Add from Dropbox</div>
        <div className="dropbox"></div>
      </DropboxChooser>
    </div>
  );
};

VariableImage.propTypes = {
  variable: PropTypes.shape({
    values: PropTypes.array.isRequired,
    key: PropTypes.string.isRequired,
  }),
  addValue: PropTypes.func.isRequired,
  removeValue: PropTypes.func.isRequired,
};

export default VariableImage;
