import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/auth";

import AuthenticatedApp from "./authenticated-app";
import UnauthenticatedApp from "./unauthenticated-app";

const App = () => {
  return (
    <Router>
      <AuthProvider>
        {({ isLoggedIn }) => (isLoggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />)}
      </AuthProvider>
    </Router>
  );
};

export default App;
