import React, { useEffect, useState, memo } from "react";
import firebase from "../lib/firebase";
import { useHistory } from "react-router-dom";

const AuthContext = React.createContext();

let AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [isInitialized, setIsInitialized] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(data => {
      setUser(data);
      setIsInitialized(true);
    });

    return () => unsubscribe();
  }, [setUser]);

  const isLoggedIn = !!user;

  function logout() {
    firebase
      .auth()
      .signOut()
      .then(() => history.replace("/"));
  }

  return (
    <AuthContext.Provider value={{ isInitialized, user, isLoggedIn, logout }}>
      {isInitialized && <AuthContext.Consumer>{children}</AuthContext.Consumer>}
    </AuthContext.Provider>
  );
};

AuthProvider = memo(AuthProvider);

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
