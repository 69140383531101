import React, { useState } from "react";
import PropTypes from "prop-types";

import VariableControl from "./variables/control";

const defaultSettings = [
  {
    key: "palette",
    name: "Palette",
    type: "enum",
    format: "color",
    values: ["#000000", "#ffffff"],
    select: [],
    enabled: true,
  },
  {
    key: "logos",
    name: "Logos",
    type: "enum",
    format: "image",
    values: [],
    select: [],
    enabled: true,
  },
  {
    key: "font-families",
    name: "Font Families",
    type: "enum",
    format: "text",
    values: ["Helvetica"],
    select: [],
    enabled: true,
  },
];

const CompanyForm = ({ company, saveCompany }) => {
  const [isDirty, setIsDirty] = useState(false);
  const [settings, setSettings] = useState(company.settings || defaultSettings);

  function update(config) {
    setSettings(config.variables);
    setIsDirty(true);
  }

  function persist() {
    setIsDirty(false);
    saveCompany({ settings });
  }

  return (
    <>
      <h2 className="text-xl py-4">Brand Settings</h2>

      {settings.map(variable => (
        <VariableControl
          editable={false}
          key={variable.key}
          variable={variable}
          config={{ variables: settings }}
          setConfig={update}
        />
      ))}

      {isDirty && (
        <button type="button" className="btn btn-blue my-4" onClick={persist}>
          Save
        </button>
      )}
    </>
  );
};

CompanyForm.propTypes = {
  company: PropTypes.shape({
    settings: PropTypes.array,
  }),
  saveCompany: PropTypes.func.isRequired,
};

export default CompanyForm;
