import React from "react";
import PropTypes from "prop-types";
import { IoMdClose } from "react-icons/io";

const VariableText = ({ variable, addValue, removeValue, updateValue, type = "text" }) => {
  return (
    <div>
      {variable.values.map((value, position) => (
        <div className="row flex" key={[variable.key, position].join(".")}>
          <input
            type={type}
            className="form-input mt-1 block flex-1"
            value={value}
            onChange={e =>
              updateValue(
                variable.key,
                position,
                variable.format === "number" ? parseInt(e.target.value) : e.target.value
              )
            }
          />
          <button
            type="button"
            className="flex-none text-2xl p-1"
            onClick={() => removeValue(variable.key, position)}
          >
            <IoMdClose />
          </button>
        </div>
      ))}
      <button
        type="button"
        className="btn text-xs border-white"
        onClick={() => addValue(variable.key)}
      >
        + Add
      </button>
    </div>
  );
};

VariableText.propTypes = {
  variable: PropTypes.shape({
    key: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
  }),
  addValue: PropTypes.func.isRequired,
  removeValue: PropTypes.func.isRequired,
  updateValue: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

VariableText.defaultProps = {
  type: "text",
};

export default VariableText;
