import React from "react";
import PropTypes from "prop-types";
import { PopupboxContainer } from "react-popupbox";
import "react-popupbox/dist/react-popupbox.css";

import ResultFrame from "./frame";
import ProgressBar from "./progress-bar";

const ResultSet = ({ config, results, tryCount }) => {
  const {
    template: { dimensions },
  } = config;
  const gridTemplateColumns = `repeat(auto-fill, minmax(${dimensions.width / 2}px, 1fr))`;

  return (
    <>
      <ProgressBar value={results.length} total={config.maxVariations} className="w-full" />

      <div className="p-4">
        {tryCount > 0 && (
          <h1 className="pb-4 text-center">
            {results.length} results (tried: {tryCount})
          </h1>
        )}

        <div
          className="results pl-4 pr-4"
          style={{
            gridTemplateColumns,
          }}
        >
          {results.map(result => (
            <ResultFrame result={result} dimensions={config.template.dimensions} key={result.key} />
          ))}
        </div>
      </div>
      <PopupboxContainer />
    </>
  );
};

ResultSet.propTypes = {
  results: PropTypes.array.isRequired,
  config: PropTypes.shape({
    maxRuns: PropTypes.number.isRequired,
    maxVariations: PropTypes.number.isRequired,
    template: PropTypes.shape({
      dimensions: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }),
  tryCount: PropTypes.number.isRequired,
};

export default ResultSet;
