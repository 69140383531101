import React from "react";
import warhol from "../images/warhol.jpg";

import firebase from "../lib/firebase";

const LoginPage = () => {
  async function login() {
    const provider = new firebase.auth.GoogleAuthProvider();
    await firebase.auth().signInWithPopup(provider);
  }

  return (
    <div className="h-screen">
      <div className="container mx-auto h-full flex flex-col justify-center items-center">
        <img src={warhol} height="400" className="block my-16 flex-1" />
        <button type="button" className="btn btn-blue mb-32" onClick={() => login()}>
          Log In
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
