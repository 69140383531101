import React from "react";
import PropTypes from "prop-types";

const VariableSettings = ({ variable, setVariable, deleteVariable }) => {
  function set(key, value) {
    const newVariable = Object.assign({}, variable, { [key]: value });
    if (newVariable.type === "range" && !newVariable.min) {
      newVariable.min = 0;
      newVariable.max = 10;
      newVariable.step = 1;
    }
    if (newVariable.type === "enum" && !newVariable.values) {
      newVariable.values = [];
    }
    setVariable(variable.key, newVariable);
  }

  function updateSelector(selector, key, value) {
    selector[key] = value;
    set("select", [...variable.select]);
  }

  return (
    <div className="-mx-4 mt-0 mb-4 p-4 bg-gray-700 text-white">
      <div className="flex">
        <div className="form-field flex-1 pr-2">
          <label className="block uppercase bold text-xs">Name</label>
          <input
            type="text"
            className="form-input"
            value={variable.name}
            onChange={e => set("name", e.target.value)}
          />
        </div>

        <div className="form-field flex-1 pl-2">
          <label className="block uppercase bold text-xs">Format</label>
          <select
            value={variable.format}
            onChange={e => set("format", e.target.value)}
            className="form-select w-full"
          >
            <option value="text">Text</option>
            <option value="image">Image</option>
            <option value="color">Color</option>
          </select>
        </div>
      </div>

      <div className="mt-4">
        {variable.select.map((selector, i) => (
          <div className="flex" key={i}>
            <div className="form-field flex-1 pr-2">
              <label className="block uppercase bold text-xs">Select Layer With Label</label>
              <input
                type="text"
                className="form-input"
                value={selector.layer.label}
                onChange={e => updateSelector(selector, "layer", { label: e.target.value })}
              />
            </div>

            <div className="form-field flex-1 pl-2">
              <label className="block uppercase bold text-xs">Layer Property</label>
              <select
                value={selector.path}
                onChange={e => updateSelector(selector, "path", e.target.value)}
                className="form-select w-full"
              >
                <option value="top">top</option>
                <option value="left">left</option>
                <option value="width">width</option>
                <option value="height">height</option>
                <option value="richText.0.insert">text</option>
                <option value="color">imageUrl</option>
                <option value="fontSize">fontSize</option>
                <option value="fontFamily">fontFamily</option>
                <option value="fontWeight">fontWeight</option>
                <option value="textTransform">textTransform</option>
                <option value="textShadow">textShadow</option>
                <option value="justifyContent">justifyContent</option>
                <option value="lineHeight">lineHeight</option>
                <option value="imageUrl">imageUrl</option>
                <option value="backgroundColor">backgroundColor</option>
                <option value="borderRadius">borderRadius</option>
                <option value="borderColor">borderColor</option>
                <option value="borderStyle">borderStyle</option>
                <option value="borderWidth">borderWidth</option>
                <option value="opacity">opacity</option>
              </select>
            </div>
          </div>
        ))}
      </div>

      <div className="flex mt-4">
        <div className="form-field flex-initial">
          <label className="block uppercase bold text-xs">Type</label>
          <select
            value={variable.type}
            onChange={e => set("type", e.target.value)}
            className="form-select w-full"
          >
            <option value="enum">List</option>
            <option value="range">Range</option>
          </select>
        </div>

        <div className="mt-6 flex-1 text-right">
          <button type="button" className="btn" onClick={() => deleteVariable()}>
            Delete Variable
          </button>
        </div>
      </div>
    </div>
  );
};

VariableSettings.propTypes = {
  variable: PropTypes.shape({
    name: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    select: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
      })
    ),
  }),
  setVariable: PropTypes.func.isRequired,
  deleteVariable: PropTypes.func.isRequired,
};

export default VariableSettings;
