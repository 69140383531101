/* eslint require-atomic-updates: 0 */
import relativeLuminance from "relative-luminance";
import firebase from "./firebase";

// Size of the map regions, in pixels. Smaller regions leads to better accuracy but
// takes longer
const regionSize = 40;

// Draw region squares on top of our canvas and attach it to the page
const enableDebugging = false;

// We re-use a background multiple times, so don't need to continually compute its
// luminance map
const cache = {};

const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/;

function hashCode(str) {
  return str
    .split("")
    .reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0);
}

// Returns a canvas with the image drawn on in the same dimensions and "cover" method
// as used by the actual background layer.
export function makeCanvas(img, canvasDimensions = {}) {
  const { naturalWidth, naturalHeight } = img;
  const naturalDimensions = { width: naturalWidth, height: naturalHeight };

  // This is really just for testing, in real scenarios we'd always be passing
  // in canvasDimensions. But if we didn't, our original img might be huge and
  // this scales it down to a reasonable size
  if (!canvasDimensions) {
    // Set some reasonable defaults if dimensions were not passed in
    const maxImageSize = 800; // px

    const ratio = naturalDimensions.width / naturalDimensions.height;
    if (ratio > 1) {
      canvasDimensions.width = maxImageSize;
      canvasDimensions.height = canvasDimensions.width / ratio;
    } else {
      canvasDimensions.height = maxImageSize;
      canvasDimensions.width = canvasDimensions.height * ratio;
    }
  }

  const drawOffsets = scaleToCover(
    { width: naturalWidth, height: naturalHeight },
    canvasDimensions
  );
  const { top, left, width, height } = drawOffsets;

  const canvas = document.createElement("canvas");
  canvas.width = canvasDimensions.width;
  canvas.height = canvasDimensions.height;

  const ctx = canvas.getContext("2d");

  ctx.drawImage(img, left, top, width, height);

  return canvas;
}

// Simulates background-size: 'cover'. Currently hard-coded to centered.
function scaleToCover(natural, canvas) {
  const canvasRatio = canvas.width / canvas.height;
  const naturalRatio = natural.width / natural.height;
  const croppedSides = canvasRatio < naturalRatio;

  let scale, width, height, top, left;
  if (croppedSides) {
    scale = canvas.height / natural.height;
    width = natural.width * scale;
    height = canvas.height;
    left = Math.round((canvas.width - width) / 2);
    top = 0;
  } else {
    scale = canvas.width / natural.width;
    height = natural.height * scale;
    width = canvas.width;
    top = Math.round((canvas.height - height) / 2);
    left = 0;
  }

  return { width, height, left, top };
}

// Waits for an <img> element to load and then returns it when it is ready to be
// written to a canvas.
export function loadImage(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      img.onerror = img.onload = null;
      resolve(img);
    };

    img.onerror = err => {
      img.onerror = img.onload = null;
      reject(err);
    };

    img.crossOrigin = "Anonymous";
    img.src = url;
  });
}

function makeRegions(ctx) {
  const { canvas } = ctx;
  const xBuckets = Math.ceil(canvas.width / regionSize);
  const yBuckets = Math.ceil(canvas.height / regionSize);

  const regions = [];

  for (let y = 0; y < yBuckets; y++) {
    regions.push([]);

    for (let x = 0; x < xBuckets; x++) {
      // Some regions may be clipped off, and fetching pixels
      // outside of the canvas throws off our calculations
      const width = Math.min(regionSize, canvas.width - x * regionSize);
      const height = Math.min(regionSize, canvas.height - y * regionSize);

      const region = ctx.getImageData(x * regionSize, y * regionSize, width, height);
      regions[y].push(region);
    }
  }

  return regions;
}

function percentile(arr, p) {
  if (arr.length === 0) return 0;

  const index = arr.length * p;
  const lower = Math.floor(index);
  const upper = lower + 1;
  const weight = index % 1;

  if (upper >= arr.length) return arr[lower];
  return arr[lower] * (1 - weight) + arr[upper] * weight;
}

// Return some aggregate luminance data for a region of pixels
function getRegionLuminance(region, coordinates) {
  const { data } = region;
  const values = [];
  const histogram = {};

  for (let i = 0, len = data.length; i < len; i += 4) {
    const luminance = relativeLuminance(data.slice(i, i + 3));
    values.push(luminance);
    const rounded = luminance.toFixed(2);
    histogram[rounded] ? histogram[rounded]++ : (histogram[rounded] = 1);
  }

  // Sort by value ascending
  values.sort((a, b) => a - b);

  const valueCount = values.length;
  const average = values.reduce((a, b) => (a += b), 0) / valueCount;
  const p90 = percentile(values, 0.9);
  const p10 = percentile(values, 0.1);

  const entropy =
    Object.values(histogram).reduce((acc, v) => {
      const p = v / valueCount;
      return (acc += (p * Math.log(p)) / Math.log(2));
    }, 0) * -1;

  return { entropy, average, p10, p90, ...coordinates };
}

// Draw region squares on top of our canvas and attach it to the page
function drawDebuggingInfo(ctx, region) {
  const left = region.x * regionSize;
  const top = region.y * regionSize;
  const width = regionSize;
  const height = regionSize;

  const { contrastDark, contrastLight } = region;

  ctx.font = "14px Helvetica";
  const offset = (regionSize - 10) / 2;

  let text;

  if (contrastDark > 100) {
    const alpha = (contrastDark - 50) / 255;
    text = Math.round(contrastDark / 25) + "";

    ctx.fillStyle = `rgba(0, 0, 0, ${alpha})`;
    ctx.strokeStyle = "#ffffff";
  } else if (contrastLight > 100) {
    const alpha = (contrastLight - 50) / 255;
    text = Math.round(contrastLight / 25) + "";

    ctx.fillStyle = `rgba(255, 255, 255, ${alpha})`;
    ctx.strokeStyle = "#000000";
  }

  if (text) {
    ctx.fillRect(left, top, width, height);
    ctx.textBaseline = "top";
    ctx.strokeText(text, left + offset, top + offset);
  }
}

function parseColor(color) {
  let r, g, b, alpha;

  if (color[0] === "#") {
    if (color.length === 4) {
      color = ["#", color[1], color[1], color[2], color[2], color[3], color[3]].join("");
    }

    r = parseInt(color.slice(1, 3), 16);
    g = parseInt(color.slice(3, 5), 16);
    b = parseInt(color.slice(5, 7), 16);
    alpha = 1.0;
  } else {
    const match = color.match(rgbaRegex);
    if (match) {
      match.shift();
      [r, g, b, alpha] = match;
    } else {
      throw new Error(`Bad color: ${color}`);
    }
  }

  return { r, g, b, alpha };
}

export function getContrast(l1, l2) {
  let contrast = (l1 + 0.05) / (l2 + 0.05);
  if (contrast < 1) {
    contrast = 1 / contrast;
  }
  return contrast;
}

export function getLuminance(color) {
  const { r, g, b } = parseColor(color);
  return relativeLuminance([r, g, b]);
}

// Hit test to calculate the average and minimum luminance of the regions that comprise
// our dimensions
export function testLuminance(map, { width, height, top, left, color }) {
  const { regionSize } = map;
  const regionX0 = Math.floor(left / regionSize);
  const regionY0 = Math.floor(top / regionSize);
  const regionX1 = Math.floor((left + width) / regionSize);
  const regionY1 = Math.floor((top + height) / regionSize);

  let minLuminance = Infinity;
  let maxLuminance = 0;
  let maxEntropy = 0;
  let totalLuminance = 0;
  let regionCount = 0;

  for (let y = regionY0; y <= regionY1; y++) {
    for (let x = regionX0; x <= regionX1; x++) {
      const region = map.luminances[y] && map.luminances[y][x];
      if (!region) {
        continue;
      }

      maxEntropy = Math.max(maxEntropy, region.entropy);
      minLuminance = Math.min(minLuminance, region.p10);
      maxLuminance = Math.max(maxLuminance, region.p90);
      totalLuminance += region.average;
      regionCount++;
    }
  }

  const averageLuminance = totalLuminance / regionCount;
  const { r, g, b, alpha } = parseColor(color);

  const layerLuminance = relativeLuminance([r, g, b]);

  // Calculate the layer's distance from the [min, max] range
  let contrast;
  if (layerLuminance > maxLuminance) {
    contrast = getContrast(maxLuminance, layerLuminance);
  } else if (layerLuminance < minLuminance) {
    contrast = getContrast(minLuminance, layerLuminance);
  } else {
    contrast = 0;
  }

  const score = contrast * alpha * ((12 - maxEntropy) / 200);

  return {
    entropy: maxEntropy,
    layerLuminance,
    backgroundLuminance: [minLuminance, averageLuminance, maxLuminance],
    contrast,
    score,
  };
}

// Returns a 2d grid of regions with associated aggregate luminance
// data for each
export default async function getLuminanceMap(url, dimensions = {}, size = "cover") {
  const cacheKey = "k-" + hashCode([url, dimensions.width, dimensions.height, size].join("-"));

  if (cache[cacheKey]) {
    return cache[cacheKey];
  }

  const db = firebase.firestore();
  const docRef = db.collection("luminance-maps").doc(cacheKey);
  const doc = await docRef.get();

  if (doc.exists) {
    let result = JSON.parse(doc.data().data);
    cache[cacheKey] = result;
    return result;
  }

  console.log(`Calculating luminance for ${url}`);

  const img = await loadImage(url);
  const canvas = makeCanvas(img, dimensions, size);
  const ctx = canvas.getContext("2d");

  const regions = makeRegions(ctx);

  const luminances = regions.map((row, y) => {
    return row.map((region, x) => getRegionLuminance(region, { x, y }));
  });

  if (enableDebugging) {
    luminances.forEach(row => {
      row.forEach(region => {
        drawDebuggingInfo(ctx, region);
      });
    });

    window.parent.document.body.appendChild(canvas);
  }

  const result = {
    luminances,
    regionSize,
    originalWidth: img.naturalWidth,
    originalHeight: img.naturalHeight,
    canvasWidth: canvas.width,
    canvasHeight: canvas.height,
  };

  await docRef.set({ data: JSON.stringify(result) });

  cache[cacheKey] = result;
  return result;
}
