import getLuminanceMap, { getLuminance, testLuminance, getContrast } from "./luminance-map.js";
import Variable from "./variable";
import thumbnail from "./thumbnail";

class ConstraintError extends Error {}

export default class Template {
  constructor(layers, variables, constraints = []) {
    this.setupLayers(layers);
    this.setupVariables(variables);
    this.setupConstraints(constraints);
  }

  static registerConstraint(name, settings, fn) {
    this.constraints = this.constraints || [];
    this.constraints.push({ name, settings, fn, enabled: true });
  }

  static get fullConstraintList() {
    return this.constraints.map(c => c.name);
  }

  setupLayers(layers) {
    this.layers = layers;

    // caching, since we will serialize/deserialize this repeatedly to clone
    this._jsonString = JSON.stringify(this.layers);
  }

  setupConstraints(constraints) {
    for (let constraint of constraints) {
      constraint.settingsObject = constraint.settings.reduce((acc, setting) => {
        acc[setting.name] = setting.value;
        return acc;
      }, {});
    }

    this.constraints = constraints;
  }

  setupVariables(variables) {
    this.variables = variables.map(v => new Variable(v, this.layers));
  }

  // Return a fresh copy of the original layer list, since we run multiple times
  // and don't want to mutate our original
  layerClone() {
    return JSON.parse(this._jsonString);
  }

  // Generate a value for each variable and insert it into the variable's spot in the
  // layer structure
  async generateOutput() {
    const outputLayers = this.layerClone();
    const variableOutputs = {};
    let errors = [];
    let debugInfo = {};

    for (let variable of this.variables) {
      const value = variable.replace(outputLayers);
      variableOutputs[variable.name] = value;
    }

    for (let { fn, settings } of this.constraints.filter(c => c.enabled)) {
      const settingValues = settings.reduce((acc, setting) => {
        acc[setting.name] = setting.value;
        return acc;
      }, {});

      let result;
      try {
        result = await fn(variableOutputs, outputLayers, settingValues);
      } catch (e) {
        console.error(e);
        result = { errors: [e] };
      }

      if (result.errors) {
        errors = [...errors, ...result.errors];
        delete result.errors;
      }
      Object.assign(debugInfo, result.info);
    }

    return { errors, layers: outputLayers, variables: variableOutputs, debugInfo };
  }
}

Template.registerConstraint("[INTERNAL] Cropped images", [], async (variables, layers) => {
  layers
    .filter(l => l.imageUrl && l.imageUrl.length)
    .forEach(layer => {
      layer.imageUrl = thumbnail(layer.imageUrl, {
        ...layer,
        fit: "cover",
        operation: "resize",
        position: layer.backgroundCropPosition,
      });
    });

  return { errors: [] };
});

Template.registerConstraint(
  "Require high contrast between foreground and background colors",
  [
    {
      name: "backgroundLabel",
      format: "text",
      value: "Background",
    },
    {
      name: "minimumContrast",
      format: "number",
      value: 7.5,
      min: 1.0,
      max: 21.0,
      step: 0.5,
    },
  ],
  async (variables, layers, config) => {
    const errors = [];

    const { minimumContrast, backgroundLabel } = config;

    const backgroundLayer = layers.find(layer => layer.label === backgroundLabel);
    if (!backgroundLayer) {
      return { errors: [], info: { missingBackgroundLayer: true } };
    }

    const { backgroundColor } = backgroundLayer;
    if (!backgroundColor) {
      return { errors: [], info: { missingBackgroundColor: true } };
    }
    const backgroundLuminance = getLuminance(backgroundColor);

    const textLayers = layers.filter(layer => layer.type === "richText");
    const contrasts = [];

    for (let layer of textLayers) {
      const colors = layer.richText.map(s => s.attributes && s.attributes.color).filter(Boolean);
      colors.push(layer.color);

      for (let color of colors.filter(Boolean)) {
        const luminance = getLuminance(color);

        const contrast = getContrast(luminance, backgroundLuminance);
        contrasts.push(contrast);

        if (contrast < minimumContrast) {
          errors.push(
            new ConstraintError(
              `Need contrast of ${minimumContrast}, got ${contrast} for fg ${color} and bg ${backgroundColor}`
            )
          );
        }
      }
    }

    return { errors, info: { backgroundLuminance, contrasts } };
  }
);

Template.registerConstraint(
  "Require high contrast between heading text and background image",
  [
    {
      name: "headingLabel",
      format: "text",
      value: "Heading",
    },
    {
      name: "minimumContrast",
      format: "number",
      value: 3.5,
      min: 1.0,
      max: 21.0,
      step: 0.5,
    },
    {
      name: "maximumBackgroundEntropy",
      format: "number",
      value: 4.5,
      min: 0.25,
      max: 10.0,
      step: 0.25,
    },
  ],
  async (variables, layers, config) => {
    const errors = [];

    // These are arbitrarily set based on what looks good; increase to require higher contrast between
    // text and its background

    const { headingLabel, minimumContrast, maximumBackgroundEntropy } = config;

    const backgroundLayer = layers.reverse().find(t => t.type === "staticPic");
    if (!backgroundLayer) {
      return { errors: [], info: { missingBackgroundImageLayer: true } };
    }
    const backgroundImage = backgroundLayer.imageUrl;

    const map = await getLuminanceMap(backgroundImage, backgroundLayer, "cover");

    const heading = layers.find(t => t.label === headingLabel);

    if (!heading) {
      return { errors: [], info: { missingHeadingLayer: true } };
    }

    const results = testLuminance(map, heading);

    if (results.entropy > maximumBackgroundEntropy) {
      errors.push(
        new ConstraintError(
          `Need an entropy lower than ${maximumBackgroundEntropy}, got ${results.entropy}`
        )
      );
    }

    if (results.contrast < minimumContrast) {
      errors.push(
        new ConstraintError(
          `Need a contrast higher than ${minimumContrast}, got ${results.contrast}`
        )
      );
    }

    return { errors, info: results };
  }
);
