const BASE = "https://thumbnails.movableink-dmz.com/1/image/";

export default function thumbnail(url, opts) {
  opts.operation = opts.operation || "resize";
  const image = new URL(`${BASE}${opts.operation}`);
  const params = image.searchParams;

  if (opts.width) {
    params.set("width", opts.width);
  }
  if (opts.height) {
    params.set("height", opts.height);
  }
  if (opts.fit) {
    params.set("fit", opts.fit);
  }
  if (opts.position) {
    params.set("position", opts.position);
  }

  params.set("url", url);

  return image.toString();
}
