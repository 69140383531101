import React, { useState } from "react";
import PropTypes from "prop-types";

import "./tabs.css";

const Tabs = ({ children }) => {
  const [active, setActive] = useState(children[0].props.label);

  return (
    <div className="tabs flex-1 flex flex-col overflow-auto">
      <ul className="tab-list flex border-b border-gray-500 justify-center">
        {children.map(child => (
          <li key={child.props.label} className="-mb-px">
            <button
              type="button"
              className={
                active === child.props.label
                  ? "tab-active focus:outline-none"
                  : "tab focus:outline-none"
              }
              onClick={() => setActive(child.props.label)}
            >
              {child.props.label}
            </button>
          </li>
        ))}
      </ul>

      <div className="tab-content flex-1 overflow-y-auto overflow-x-hidden">
        {children.find(child => child.props.label === active)}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tabs;
