import React from "react";
import PropTypes from "prop-types";
import Tabs from "../tabs";

const DebugInfo = ({ result, dimensions }) => {
  const { layers, debugInfo, variables } = result;
  const style = { width: "25vw", height: `${dimensions.height}px` };
  return (
    <div className="p-4 flex" style={style}>
      <Tabs>
        <div label="Debug Info">
          <div className="text-xs whitespace-pre-wrap p-4 overflow-y-auto overflow-x-hidden">
            {JSON.stringify(debugInfo, null, 2)}
          </div>
        </div>
        <div label="Variables">
          <div className="text-xs whitespace-pre-wrap p-4 overflow-y-auto overflow-x-hidden">
            {JSON.stringify(variables, null, 2)}
          </div>
        </div>
        <div label="Layers">
          <p>
            Dimensions: {dimensions.width} &times; {dimensions.height}
          </p>

          <div className="text-xs whitespace-pre-wrap p-4 overflow-y-auto overflow-x-hidden">
            {JSON.stringify(layers, null, 2)}
          </div>
        </div>
      </Tabs>
    </div>
  );
};

DebugInfo.propTypes = {
  result: PropTypes.shape({
    layers: PropTypes.array.isRequired,
    debugInfo: PropTypes.object.isRequired,
    variables: PropTypes.object.isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
};

export default DebugInfo;
