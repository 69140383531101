import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import useTask, { isAbortError } from "use-task";
import { useHistory } from "react-router-dom";

import VariableForm from "./forms/variable-form";
import ConstraintsForm from "./forms/constraints-form";
import ConfigForm from "./forms/config-form";

import Results from "./results";
import Tabs from "./tabs";
import Loader from "./loader";
import TemplateSelector from "./template-selector";

import generator from "../lib/generator";
import Template from "../lib/template";

import "./explore.css";

const { constraints } = Template;

function initialConfig(templates) {
  return {
    maxRuns: 10000,
    maxVariations: 20,
    constraints,
    auto: false,
    template: templates[0],
    layers: templates[0].layers,
    variables: templates[0].variables,
  };
}

const Explore = ({ templates }) => {
  const [results, setResults] = useState([]);
  const [tryCount, setTryCount] = useState(0);
  const [config, _setConfig] = useState(initialConfig(templates));

  const history = useHistory();

  function clearResults() {
    setResults([]);
  }

  // We want setConfig to have a side-effect of run(), but also be stable so that
  // re-rendering IndexPage does not re-render sub-components (TODO: fix eslint issue)
  const setConfig = useCallback(function setCfg(newConfig) {
    _setConfig(newConfig);
    if (newConfig.auto) {
      run({ ...newConfig, maxVariations: 1 });
    }
    // eslint-disable-next-line
  }, []);

  const setConfigAndClearResults = useCallback(
    function setCfgAndClear(newConfig) {
      setConfig(newConfig);
      clearResults();
    },
    [setConfig]
  );

  const [generate, generateState] = useTask(generator, { keep: "last" });

  async function run(generatorConfig = config) {
    clearResults();

    try {
      await generate(generatorConfig, setTryCount, setResults);
    } catch (e) {
      if (!isAbortError(e)) {
        console.error(e);
        throw e;
      }
    }
  }

  return (
    <main className="flex-1 flex overflow-hidden">
      <div className="flex-none w-full md:max-w-md bg-gray-100 flex flex-col">
        <div className="actions flex p-4 pb-0">
          <div className="flex-1">
            <button
              type="button"
              className="cursor-pointer underline block h-full p-2"
              onClick={() => history.goBack()}
            >
              &laquo; Back
            </button>
          </div>
          <button
            type="button"
            disabled={generateState.isRunning}
            className="generate btn btn-blue"
            onClick={() => (config.auto ? run({ ...config, maxVariations: 1 }) : run())}
          >
            {config.auto ? "Regenerate" : "Generate"}
          </button>
        </div>

        <div className="text-right px-4">
          <input
            type="checkbox"
            value={config.auto}
            onChange={() => setConfig({ ...config, auto: !config.auto })}
          />
          &nbsp; auto
        </div>

        <label className="block pl-4 pr-4 pb-6">
          <TemplateSelector
            templates={templates}
            config={config}
            setConfig={setConfigAndClearResults}
          />
        </label>

        <Tabs>
          <div label="Variables">
            <VariableForm config={config} setConfig={setConfig} />
          </div>
          <div label="Constraints">
            <ConstraintsForm config={config} setConfig={setConfig} />
          </div>
          <div label="Config" className="p-4">
            <ConfigForm config={config} setConfig={setConfig} />
          </div>
        </Tabs>

        <Loader
          results={results}
          config={config}
          setResults={setResults}
          setConfig={setConfig}
          className="flex"
        />
      </div>

      <div className="flex-1 bg-gray-900 text-white overflow-auto h-screen">
        <Results results={results} tryCount={tryCount} config={config} />
      </div>
    </main>
  );
};

Explore.propTypes = {
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      layers: PropTypes.array.isRequired,
      variables: PropTypes.array.isRequired,
    })
  ).isRequired,
};

export default Explore;
