import React, { memo } from "react";
import PropTypes from "prop-types";

const ConfigForm = ({ config, setConfig }) => {
  return (
    <>
      <div className="row mt-2 mb-4">
        <label>Variation Count</label>
        <input
          type="number"
          className="form-input mt-1 block flex-1"
          value={config.maxVariations}
          onChange={e => setConfig({ ...config, maxVariations: parseInt(e.target.value) })}
        />
        <div className="description mt-1 mb-2 text-xs text-gray-800">
          Try to generate this many variations. We may generate fewer if we can&rsquo;t find enough
          acceptable variations before hitting the maximum number of runs. (below)
        </div>
      </div>
      <div className="row mt-2 mb-4">
        <label>Max Runs</label>
        <input
          type="number"
          className="form-input mt-1 block flex-1"
          value={config.maxRuns}
          onChange={e => setConfig({ ...config, maxRuns: parseInt(e.target.value) })}
        />
        <div className="description mt-1 mb-2 text-xs text-gray-800">
          Give up after this number of tries, even if we haven&rsquo;t generated enough generations
          yet.
        </div>
      </div>
    </>
  );
};

ConfigForm.propTypes = {
  config: PropTypes.shape({
    maxRuns: PropTypes.number.isRequired,
    maxVariations: PropTypes.number.isRequired,
  }),
  setConfig: PropTypes.func.isRequired,
};

export default memo(ConfigForm);
