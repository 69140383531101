import React, { memo } from "react";
import PropTypes from "prop-types";
import VariableControl from "./variables/control";

const defaultVariable = {
  name: "my-new-variable",
  enabled: true,
  select: [
    {
      layer: { label: "My Layer" },
      path: "richText.0.insert",
    },
  ],
  type: "enum",
  format: "text",
  values: ["Some copy", "Some other copy"],
};

const VariableForm = ({ config, setConfig }) => {
  const { variables } = config;

  function addVariable() {
    const newVariable = JSON.parse(JSON.stringify(defaultVariable));
    newVariable.key = `v-${Math.random()}`;
    setConfig({ ...config, variables: [...variables, newVariable] });
  }

  return (
    <form>
      <div className="variables">
        {variables.map(variable => (
          <VariableControl
            key={variable.key}
            variable={variable}
            config={config}
            setConfig={setConfig}
          />
        ))}
      </div>
      <button
        type="button"
        onClick={addVariable}
        className="btn bg-gray-700 text-white m-4 mx-auto block"
      >
        Add New Variable
      </button>
    </form>
  );
};

VariableForm.propTypes = {
  config: PropTypes.shape({
    variables: PropTypes.array.isRequired,
  }),
  setConfig: PropTypes.func.isRequired,
};

export default memo(VariableForm);
