import React, { memo } from "react";
import PropTypes from "prop-types";

const TemplateSelect = ({ templates, config, setConfig }) => {
  function setTemplate(name) {
    const template = templates.find(t => t.name === name);
    setConfig({ ...config, layers: template.layers, variables: template.variables, template });
  }

  return (
    <>
      <span>Base Template</span>
      <select
        name="template"
        value={config.template.name}
        onChange={e => setTemplate(e.target.value)}
        className="form-select text-gray-900 block w-full mt-1"
      >
        {templates.map(template => (
          <option key={template.name} value={template.name}>
            &nbsp;{template.name}
          </option>
        ))}
      </select>
    </>
  );
};

TemplateSelect.propTypes = {
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      layers: PropTypes.array.isRequired,
      variables: PropTypes.array.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setConfig: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
};

export default memo(TemplateSelect);
